import { SearchWindowType } from "src/scripts/typings/WindowType";

// Tested
export function updateGtagConsentFromPreferences(windowScope: SearchWindowType, performance: boolean, personalisation: boolean) {
	windowScope.gtag ??= function (...args) {
		windowScope.dataLayer = windowScope?.dataLayer || [];
		windowScope.dataLayer.push(args);
	};
	windowScope.gtag("consent", "update", {
		ad_storage: performance === false ? "denied" : "granted",
		ad_user_data: performance === false ? "denied" : "granted",
		ad_personalization: personalisation === false ? "denied" : "granted",
		analytics_storage: performance === false ? "denied" : "granted",
		personalization_storage: personalisation === false ? "denied" : "granted",
		functionality_storage: "granted",
		security_storage: "granted",
	});
	windowScope.gtag("event", "consent_updated", {});
	if (performance) windowScope.gtag("event", "performance_consent_granted", {});
	if (personalisation) windowScope.gtag("event", "personalisation_consent_granted", {});
	if (performance && personalisation) windowScope.gtag("event", "consent_granted", {});
}

// Tested
export function updateGtagConsentToAllowAll(windowScope: SearchWindowType) {
	updateGtagConsentFromPreferences(windowScope, true, true);
}
