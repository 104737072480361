import Cookies from "js-cookie";
import { ICookiePreferences } from "./ICookiePreferences";
import { GtmConsentAll } from "./GtmConsentAll";
import { updateGtagConsentFromPreferences } from "./GtagConsent";
export class GtmSetupConsent extends GtmConsentAll {
	private updateGtagConsentFromPreferences = updateGtagConsentFromPreferences;

	// Tested
	Init(): void {
		this.setGtmDefaultConsent();
		const preferences: ICookiePreferences | string = Cookies.getJSON("cookie-preferences");
		if (!!preferences && typeof preferences === "object" && typeof preferences.performance === "boolean" && typeof preferences.personalisation === "boolean") this.updateGtagConsentFromPreferences(this.windowHelper, preferences.performance, preferences.personalisation);
		this.loadGtm();
	}
}
